<template>
  <div>
    <header-nav :bg="bg" :text="text" />
    <div class="h1">智能背词  吃透真题</div>
    <div class="h3">- 由清北顶级教研团队倾力打造 -</div>
    <div class="screenshot-wrap">
      <img class="screenshot" src="../../assets/screenshot1.png"/>
      <img class="screenshot" src="../../assets/screenshot2.png"/>
      <img class="screenshot" src="../../assets/screenshot3.png"/>
      <img class="screenshot" src="../../assets/screenshot4.png"/>
    </div>
    <div class="notes">
      <div class="h1">研小白关心每一位考研er的声音</div>
      <div class="h2">如您在研小白英语APP的使用过程中遇到难以解决的问题，以及任何对平台发展有益的意见及建议，欢迎您直接写信到邮箱：fb@ikaoyaner.com。</div>
      <div class="h2">为了您的来信能够得到更高效的处理，请您在邮件中标注以下信息：</div>
      <ul class="ul">
        <li>您的个人或工作单位信息（公司/品牌/部门/岗位/姓名 等）</li>
        <li>您的联系方式（邮箱地址/直线电话 等）</li>
        <li>相关问题、意见或建议的具体描述（背景/案例/各类支持性材料 等）</li>
      </ul>
    </div>
    <div class="h1">更多方式关注研小白</div>
    <div class="contact-wrap">
      <div class="contact">
        <img src="../../assets/focus-gzh.png" />
        <span>关注微信公众号</span>
      </div>
      <div class="contact">
        <img src="../../assets/focus-wb.png" />
        <span>关注微博</span>
      </div>
      <div class="contact">
        <img src="../../assets/focus-xcx.png" />
        <span>关注小程序</span>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderNav from '../../components/HeaderNav'
import Footer from '../../components/Footer'
import bg from '../../assets/header-bg1.png'

export default {
  components: { HeaderNav, Footer },
  data() {
    return {
      bg,
      text: '研小白英语  - 考研英语75+必备学习神器'
    }
  }
}
</script>

<style lang="less" scoped>
  .h1{
    margin-top: 73px;
    text-align: center;
    font-size: 26px;
    color: #333333;
  }
  .h3{
    margin-top: 14px;
    text-align: center;
    font-size: 22px;
    color: #595959;
  }
  .screenshot-wrap{
    margin-top: 48px;
    margin-bottom: 80px;
    text-align: center;
    .screenshot{
      width: 240px;
      margin-right: 20px;
      box-shadow:0 0 20px 0  #eeeeee;
    }
  }
  .notes{
    width: 100%;
    padding-bottom: 60px;
    background: #FBFBFB;
    overflow: hidden;
    .h1{
      margin-bottom: 48px;
    }
    .h2{
      width: 882px;
      margin: 0 auto;
      margin-bottom: 22px;
      color: #595959;
      font-size: 22px;
      line-height: 35px;
    }
    .ul{
      width: 882px;
      margin: 0 auto;
      margin-top: -16px;
      margin-bottom: 50px;
      color: #595959;
      font-size: 16px;
      line-height: 28px;
      >li{
        margin-left: 20px;
      }
    }
  }
  .contact-wrap{
    display: flex;
    justify-content: space-between;
    width: 485px;
    margin: 62px auto 127px auto ;
    .contact{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      >span{
        margin-top: 6px;
        font-size: 16px;
        color: #595959;
      }
    }
  }
  @media screen and (max-width:500px){
    .notes {
      .h2{
        width: 350px;
      }
      .ul{
        margin-left: -28px;
        width: auto;
      }
    }
    .contact-wrap{
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      .contact{
        margin-right: 0;
      }
    }
  }
</style>