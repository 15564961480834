<template>
  <div class="hearder" :style="styleObject">
    <div class="nav-wrap">
      <img class="logo" src="../assets/logo.png"/>
      <nav class="navs">
        <router-link to="/home">
          <div class="nav">首页</div>
          <div v-if="index==1" class="nav-line nav-line-short"></div>
        </router-link>
        <router-link to="/service">
          <div class="nav">服务内容</div>
          <div v-if="index==2" class="nav-line nav-line-long"></div>
        </router-link>
        <router-link to="/info">
          <div class="nav">资讯中心</div>
          <div v-if="index==3" class="nav-line nav-line-long"></div>
        </router-link>
        <router-link to="/about">
          <div class="nav">关于我们</div>
          <div v-if="index==4" class="nav-line nav-line-long"></div>
        </router-link>
      </nav>
    </div>
    <div class="desc">{{text}}</div>
  </div>
</template>
<script>
export default {
  name: 'HeaderNav',
  props: {
    bg: {
      type: String
    },
    text: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      styleObject: {
        background: `url(${this.bg})`
      }
    }
  }
}
</script>
<style lang="less" scoped>
.hearder{
    width: 100%;
    height: 382px;
    padding-top: 1px;
    background-position: 50% 50%;
    .nav-wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1070px;
      height: 58px;
      margin: 30px auto auto auto;
      .logo{
        height: 65px;
      }
      .navs{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 516px;
        height: 100%;
        font-size: 20px;
        color: #ffffff;
        a{
          text-decoration: none;
          color: #ffffff;
        }
        .nav{
          margin-top: -20px;
        }
        .nav-line{
          height: 2px;
          margin: 0 auto;
          margin-top: 5px;
          background: #ffffff;
        }
        .nav-line-short{
          width: 27px;
        }
        .nav-line-long{
          width: 58px;
        }
      }
    }
    .desc{
      margin-top: 105px;
      text-align: center;
      font-size: 26px;
      color: #ffffff;
    }
  }
  @media screen and (max-width:500px){
    .hearder{
      .logo{
        width: 123px;
        height: 47px;
        margin-bottom: 12px;
        margin-left: 12px;
      }
      .nav-wrap{
        width: 100%;
        flex-wrap: wrap;
        .navs{
          align-items: top;
          padding: 0 12px;
        }
      }
    }
  }
</style>