<template>
  <div class="footer">
    <div><a class="link" href="https://icp.chinaz.com/info?q=yanxiaobai.com" target="_blank" title="京ICP备19037894号">京ICP备19037894号-2</a> Copyright  2019-2020 北京新辰小白教育科技有限公司 | 地址：北京市海淀区</div>
    <div>违法不良信息举报电话：12377 | <a class="link" href="https://www.12377.cn" target="_blank" title="网上有害信息举报专区">网上有害信息举报专区</a></div>
  </div>
</template>
<script>
export default {
  name: 'Footer', 
}
</script>
<style lang="less" scoped>
  .footer{
    margin: 60px 0;
    padding: 0 12px;
    text-align: center;
    line-height: 18px;
    font-size: 16px;
    color: #717171;
    .link{
      color: #717171;
      text-decoration: none;
    }
  }
</style>